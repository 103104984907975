import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  getInputRisks,
  postInputRisk,
  patchInputRisk,
  deleteInputRisk,
} from '@/api/inputs/inputRisk'
import { ApiError } from '@/models/commonsModel'
import {
  InputRisk,
  InputRiskCreateValues,
  InputRiskUpdateValues,
  InputRiskDestroyValues,
} from '@/models/inputs/inputRisksModel'
import { AppThunk } from '@/store'
import { sortBy as _sortBy } from 'lodash'

interface InputRiskState {
  inputRisk: InputRisk[]
  isLoading: boolean
}

const initialState: InputRiskState = {
  inputRisk: [],
  isLoading: false,
}

const inputRiskSlice = createSlice({
  name: 'inputRisk',
  initialState,
  reducers: {
    setInputRisk: (state, action: PayloadAction<InputRisk[]>) => {
      state.inputRisk = _sortBy(action.payload, 'id')
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    resetInputRisksStore: (state) => {
      state = initialState
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getInputRiskSuccess: (state, action: PayloadAction<InputRisk[]>) => {
      state.inputRisk = action.payload
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setInputRisk,
  requestStart,
  resetInputRisksStore,
  requestSuccess,
  getInputRiskSuccess,
  requestFailure,
} = inputRiskSlice.actions
export const inputRiskReducer = inputRiskSlice.reducer

export const fetchInputRisk = (callback?: (inputRisks: any) => {}): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getInputRisks()
    .then((response) => {
      dispatch(getInputRiskSuccess(response))
      callback && callback(response)
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}

export const createInputRisk = (
  values: InputRiskCreateValues,
  callback: () => void,
  resolve?: (error: ApiError) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postInputRisk(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateInputRisk = (
  values: InputRiskUpdateValues,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchInputRisk(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyInputRisk = (id: number, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  deleteInputRisk(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
