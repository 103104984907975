import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unauthorizedCheck } from '../accountsSlice'
import {
  postFutureRiskV2,
  patchFutureRiskV2,
  deleteFutureRiskV2,
} from '../../api/futures/futureRisks'
import { ApiError } from '../../models/commonsModel'
import {
  FutureRisks,
  FutureRiskCreateValues,
  FutureRiskUpdateValues,
  FutureRiskDestroyValues,
} from '../../models/futures/futureRisksModel'
import { AppThunk } from '../../store'

interface FutureRiskState {
  futureRisks: FutureRisks | null
  isLoading: boolean
}

const initialState: FutureRiskState = {
  futureRisks: null,
  isLoading: false,
}

const futureRisksSlice = createSlice({
  name: 'futureRisks',
  initialState,
  reducers: {
    setFutureRisk: (state, action: PayloadAction<FutureRisks | null>) => {
      state.futureRisks = action.payload
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
    resetFutureRisk: (state) => {
      state.futureRisks = initialState.futureRisks
      return state
    },
  },
})

export const {
  setFutureRisk,
  requestStart,
  requestSuccess,
  requestFailure,
  resetFutureRisk,
} = futureRisksSlice.actions
export const futureRisksReducer = futureRisksSlice.reducer

export const createFutureRiskV2 = (
  values: FutureRiskCreateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFutureRiskV2(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const updateFutureRiskV2 = (
  values: FutureRiskUpdateValues,
  id: number,
  callback: () => void,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFutureRiskV2(values, id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error, resolve))
    })
}

export const destroyFutureRiskV2 = (id: number, callback: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  deleteFutureRiskV2(id)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedCheck(error))
    })
}
