import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isUndefined as _isUndefined } from 'lodash'
import {
  getFpAccount,
  getFpAccountShowInvited,
  postFpAccountLogin,
  postFpAccountLogout,
  patchFpAccountPasswordSetup,
  patchFpAccountUpdateWhole,
  postFpAccountPasswordReset,
  patchFpAccountUpdateAuthority,
} from '@/api/admins/fpAccounts'
import apiClient from '@/api/apiClient'
import { ApiError } from '@/models/commonsModel'
import { SEX } from '@/models/familyStructuresModelFunc'
import {
  FpAccountResponse,
  FpAccount,
  FpAccountLoginValues,
  FpAccountInvitedValues,
  FpAccountPasswordUpdateValues,
  FpAccountPasswordResetValues,
  FpAccountUpdateAuthorityValues,
} from '@/models/admins/fpAccountsModel'
import { AppThunk } from '@/store'
import {
  setStorageAuthHeaders,
  removeStorageAuthHeaders,
} from '@/utils/localStorage/authHeadersStorage'

interface FpAccountState {
  fpAccount: FpAccount
  isLoading: boolean
  isLogin: boolean
}

const initialState: FpAccountState = {
  fpAccount: {
    id: 0,
    name: '',
    mail_address: '',
    name_sei: '',
    name_mei: '',
    name_sei_kana: '',
    name_mei_kana: '',
    sex: SEX.men,
    tel_number: null,
    authority_type: 'staff',
    assign_count: null,
    recent_mail_count: null,
    paid_account_acquisition_count: null,
    is_inviting: false,
    disclosed_account_count: null,
    last_login_at_str: '',
    fp_registration_code: '',
    is_system_admin: false,
  },
  isLoading: false,
  isLogin: false,
}

const fpAccountsSlice = createSlice({
  name: 'fpAccounts',
  initialState,
  reducers: {
    setFpLogind: (state) => {
      state.isLogin = true
      return state
    },
    requestStart: (state) => {
      state.isLoading = true
      return state
    },
    requestSuccess: (state) => {
      state.isLoading = false
      return state
    },
    getFpAccountSuccess: (state, action: PayloadAction<FpAccountResponse>) => {
      state.fpAccount = action.payload
      state.isLoading = false
      return state
    },
    getFpAccountShowInvitedSuccess: (state, action: PayloadAction<any>) => {
      state.fpAccount = action.payload
      state.isLoading = false
      return state
    },
    postFpAccountLogoutSuccess: (state) => {
      state.isLoading = false
      state.isLogin = false
      return state
    },
    requestFailure: (state) => {
      state.isLoading = false
      return state
    },
  },
})

export const {
  setFpLogind,
  requestStart,
  requestSuccess,
  getFpAccountSuccess,
  getFpAccountShowInvitedSuccess,
  postFpAccountLogoutSuccess,
  requestFailure,
} = fpAccountsSlice.actions
export const fpAccountsReducer = fpAccountsSlice.reducer

export const setFpLogindFunc = (): AppThunk => async (dispatch) => {
  dispatch(setFpLogind())
  dispatch(requestStart())
  dispatch(fetchFpAccount())
}

export const fetchFpAccount = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  getFpAccount()
    .then((response: FpAccountResponse) => dispatch(getFpAccountSuccess(response)))
    .catch((error) => {
      dispatch(requestFailure())
      dispatch(unauthorizedAdminCheck(error))
    })
}

export const fetchFpAccountShowInvited = (callback?: () => void): AppThunk => async (
  dispatch,
) => {
  dispatch(requestStart())
  getFpAccountShowInvited()
    .then((response) => dispatch(getFpAccountShowInvitedSuccess(response)))
    .catch(() => {
      dispatch(requestFailure())
      callback && callback()
    })
}

export const fpAccountLogin = (
  values: FpAccountLoginValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountLogin(values)
    .then((response) => {
      dispatch(requestSuccess())
      const headers = setStorageAuthHeaders(response.token)
      apiClient.setAuthHeaders(headers)

      dispatch(setFpLogindFunc())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const fpAccountLogout = (): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountLogout()
    .then(() => {
      dispatch(postFpAccountLogoutSuccess())
      removeStorageAuthHeaders()
      apiClient.setAuthHeaders({})
    })
    .catch(() => dispatch(requestFailure()))
}

export const createFpAccountPasswordReset = (
  values: FpAccountPasswordResetValues,
  resolve: (error: any) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  postFpAccountPasswordReset(values)
    .then(() => {
      dispatch(requestSuccess())
      resolve(undefined)
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateFpAccountPasswordSetup = (
  values: FpAccountPasswordUpdateValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFpAccountPasswordSetup(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const updateFpAccountUpdateWhole = (
  values: FpAccountInvitedValues,
  callback: () => void,
  resolve: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFpAccountUpdateWhole(values)
    .then(() => {
      dispatch(requestSuccess())
      callback()
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}

export const unauthorizedAdminCheck = (
  error: ApiError,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  if (error?.isUnauthorized) {
    dispatch(fpAccountLogout())
  } else {
    !_isUndefined(resolve) && resolve(error)
  }
}

export const updateFpAccountUpdateAuthority = (
  values: FpAccountUpdateAuthorityValues,
  resolve?: (error: ApiError) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStart())
  patchFpAccountUpdateAuthority(values)
    .then(() => {
      dispatch(requestSuccess())
    })
    .catch((error) => {
      dispatch(requestFailure())
      resolve(error)
    })
}
